import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../assets/main.css";


import styles from "./lender-preview.module.css";

class Breadcrumbs extends React.Component {
    constructor(props){
     super(props);
	}

    render(){
    var parsed = this.props.url.split("/");
    var crumbs = [];
    var currentCrumb = '';
    for(var i = 1; i < parsed.length; i++){
        currentCrumb = currentCrumb + parsed[i] + '/';
        crumbs.push(currentCrumb);
	}
    this.parsed = parsed;
    this.str = '';
    var breadcrumb = '';
    console.log(this.parsed);

        return(
           
            <div className="w-min-full bg-gray-200 w-full text-left text-gray-600 p-2 uppercase">
            <Link className="no-underline p-5 text-md" to={'/'}>
                        HOME
                </Link>
                /
            {Array.from({ length: this.parsed.length-1 }, (_, i) => (
              
                 
               <>
                <Link className="no-underline p-5 text-md " to={`/${crumbs[i]}`}>
                        
                      {parsed[i+1]}
                       
                </Link>
                {
                    i == 0 ? <>/</> : <></> 
                }
            
                </>
            ))}
                
            </div>
        )
	}
}

export default Breadcrumbs
    
   